$(document).ready(function () {
    let delay_in_ms = popupDelay * 1000

    function setCookie(name, value, days) {
        let date = new Date(), expires;
        if (days) {
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        } else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    function getCookie (cname){
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }

    // Funzione per controllare e visualizzare il primo popup disponibile basato sui cookie
    function checkAndShowPopup() {
        let nlModal = $('#nlModal');
        if (nlModal.length === 0) {
            for (let i = 1; i <= popupListSize; i++) {
                if (getCookie('promo-modal-' + i) === null) {
                    setTimeout(function () {
                        $('#promoPopup-' + i).modal('show');
                        setCookie('promo-modal-' + i, 1, 86400);
                    }, delay_in_ms);
                    break;
                }
            }
        }
    }

    checkAndShowPopup();
});